.icon-holder {
  width: 70px;
  height: 70px;
  margin-bottom: 15px;
  border-radius: 70px;
  background: #ddd;
  line-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
