.shadow-custom {
  box-shadow: 0px 0px 16px #0000001a;
}

.smothie-btn {
  background-color: transparent;
  border: 1px solid black;
  border-radius: 10px;
  margin-top: 30px;
  padding: 20px;
  color: #000033;
  border: 1px solid #000033;
  transition: 0.7s;
}

.smothie-btn:hover {
  background-color: #000033;
  color: white;
}
